import store from "../store/index";
import helpers from "../helpers/CookieHelpers";
import APIHelpers from "../helpers/ApiHelpers";

import {
  FETCH_POST_USERS_API
} from "../constants/urls";
// import { REQUEST_OPTIONS } from "../constants/requestOptions";
import ApiHelpers from "../helpers/ApiHelpers";

import ConfigHelper from "../helpers/ConfigHelper";

export const userService = {
  fetchPaginatedUsersList
};
async function fetchPaginatedUsersList({type, pageSize, nextPageToken, searchString, status}) {
  var url=await ConfigHelper.getAPIUrl();
	var requestLink = type ? `?Type=${type}` : "";
	requestLink += pageSize && status ? `${requestLink.startsWith('?') ? '&' : '?'}Status=${status}` : "";
	requestLink += pageSize ? `${requestLink.startsWith('?') ? '&' : '?'}PageSize=${pageSize}` : "";
	requestLink += nextPageToken ? `${requestLink.startsWith('?') ? '&' : '?'}NextPageToken=${nextPageToken}` : "";
	requestLink += searchString ? `${requestLink.startsWith('?') ? '&' : '?'}SearchString=${searchString}` : "";
	const response = await ApiHelpers.makeAPICall(
		url + FETCH_POST_USERS_API + requestLink,
		"GET",
		null,
		false
	).then(handleResponse);
	return response;
}

async function handleResponse(response) {
  console.log("handling response", response);
  if (!response.ok) {
    // alert(response.error);
    var res = await response
      .json()
      .then(
        (data) =>
          (res = {
            error: data.error,
          })
      )
      .then((res) => {
        // console.log(res);
        return res;
      });
    return Promise.reject(res.error);
  } else {
    return response.json();
  }
}
async function deleteZiyaratOffer(ziyaratOfferId) {
  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
    url + GET_ZIYARATS+'/offer'+'/'+ziyaratOfferId+'/delete',
    "Get",
    null,
    true
  ).then(handleResponse);
  console.log("response returned from API Helper", response);
  return response;
}
