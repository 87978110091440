import VueRouter from "vue-router";
import home from "./../views/Home.vue";
import Admin from "../views/Admin/Admin.vue";
import AdminHome from "../views/Admin/Views/AdminHome.vue";
import VehicleManagement from "../views/Admin/Views/VehicleManagement.vue";
import ZiyaratManagement from "../views/Admin/Views/ZiyaratManagement.vue";
import TransportManagement from "./../views/Admin/Views/TransportManagement.vue";
import StaffManagement from "./../views/Admin/Views/StaffManagement.vue";
import RoleManagement from "./../views/Admin/Views/RoleManagement.vue";
import PermissionNotAssigned from "./../views/Admin/Views/PermissionNotAssigned.vue";
let routes = [
  {
    path: '/',
    name: 'home.index',
    component: () => import('@/views/Home.vue'),

    // If the user needs to be authenticated to view this page
    meta: {
      title:"Home",
      auth: false,
    },
  },
  // {
  //   path: '/ziarat',
  //   name: 'Ziarat Detail',
  //   component: () => import('@/views/ZiaratDetail.vue'),
  //   // If the user needs to be authenticated to view this page
  //   meta: {
  //     auth: false,
  //   },
  // },
  {
    path: '/booking/:process',
    name: 'Umrah Booking',
    component: () => import('@/views/BookingForm.vue'),
    // If the user needs to be authenticated to view this page
    meta: {
      title:"Umrah Booking",
      auth: false,
      isBookingPage:true
    },
  },
  {
    path: '/evisa',
    name: 'E-Visa',
    component: () => import('@/views/E-Visa.vue'),
    // If the user needs to be authenticated to view this page
    meta: {
      title:"E-Visa",
      auth: false,
      isBookingPage:false
    },
  },
  {
    path: '/account/:page',
    name: 'Login',
    component: () => import('@/views/Account.vue'),
    // If the user needs to be authenticated to view this page
    meta: {      
      title:"Login/Register",
      auth: false,
      isBookingPage:true
    },
  },
  {
    path: '/account/:page/:code',
    name: 'Account',
    component: () => import('@/views/Account.vue'),
    // If the user needs to be authenticated to view this page
    meta: {      
      title:"Reset Password",
      auth: false,
      isBookingPage:true
    },
  },
  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: () => import('@/views/Admin/AdminLogin.vue'),
    // If the user needs to be authenticated to view this page
    meta: {      
      title:"Admin Login",
      admin: true,
      auth: false
    },
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    children: [
      {
        path: "/",
        name: "Home",
        component: AdminHome,
        meta: {   
          title:"Admin Home",
          admin: true,
          auth: true
        },
      },
      
      {
        path: "/admin/staff",
        name: "StaffManagement",
        component: StaffManagement,
        meta: {   
          title:"Staff Management",
          admin: true,
          auth: true,
          permissions:null
        },
      },
      
      {
        path: "/admin/role",
        name: "RoleManagement",
        component: RoleManagement,
        meta: {   
          title:"Role Management",
          admin: true,
          auth: true,          
          permissions:["GET_ROLES","ADD_OR_UPDATE_ROLES","DELETE_ROLE"]
        },
      },
      {
        path: "/admin/vehicle",
        name: "VehicleManagement",
        component: VehicleManagement,
        meta: {   
          title:"Vehicle Management",
          admin: true,
          auth: true,
          permissions:["GET_VEHICLES","ADD_OR_UPDATE_VEHICLE","DELETE_VEHICLE"]
        },
      },
      {
        path: "/admin/ziyarat",
        name: "ZiyaratManagement",
        component: ZiyaratManagement,
        meta: {   
          title:"Ziyarat Management",
          admin: true,
          auth: true,
          permissions:["GET_ZIYARATS","ADD_OR_UPDATE_ZIYARAT","DELETE_ZIYARAT"]
        },
      },
      {
        path: "/admin/transport",
        name: "TransportManagement",
        component: TransportManagement,
        meta: {   
          title:"Transport Management",
          admin: true,
          auth: true,
          permissions:["DELETE_TRANSPORT","ADD_OR_UPDATE_TRANSPORT","GET_TRANSPORTS"]
        },
      },
      {
        path: "/admin/permissionNotAssigned",
        name: "PermissionNotAssigned",
        component: PermissionNotAssigned,
        meta: {   
          title:"Permission Not Assigned",
          admin: true,
          auth: true
        },
      },
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  routes: routes
});

export default router;
